<template>
  <div class="all-height">
    <div class="">
      <v-card loader-height="2" class="shadow-off" :loading="loading" :disabled="loading">
        <v-card-text class="">
          <div>
            <!-- <div class="d-flex mb-4">
              <v-spacer></v-spacer>
              <v-tooltip bottom content-class="tooltip-bottom">
                <template v-slot:activator="{ on }">
                  <v-btn class="mx-0 px-0" icon v-on="on" @click="refreshData()">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Refresh</span>
              </v-tooltip>
            </div> -->
            <ApprovalsList v-if="data.length > 0" display="edit" :data="data" @loading="setLoading" :metaData="meta" :filterData="filterOptionsData"/>
            <div v-else-if='loading' class="title grey--text pa-5 text-center">
              Please wait as we load your data
            </div>
            <div v-else class="title grey--text pa-5 text-center">
              No Data
            </div>
          </div>
        </v-card-text>
      </v-card>

      <!-- <lb-dialog v-model="viewdialog" heading="Bulk Upload RU Level Access" width="800" :loading="viewloading">
        <template v-slot:body>
          <div>
            {{viewdata}}
          </div>
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn small color="error" class="mr-2" @click="performRejection(viewdata._id)">Reject</v-btn>
          <v-btn small color="success" @click="performApproval(viewdata._id)">Approve</v-btn>
        </template>
      </lb-dialog>

      <v-dialog v-model="viewdialog" max-width="600" persistent>
          <v-card :loading="viewloading" :disabled="viewloading" loader-height="2" class="">
            <v-card-text class="primary white--text d-flex align-center py-2 px-4">
              <span class="subtitle-1 white--text">View Request</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="viewdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-text>
            <v-card-text class="pt-4">
              <div class="d-flex pb-4">
                <div>
                  <div class="mb-2">
                    <v-chip small label color="info" v-if="viewdata.stage === 0">Pending</v-chip>
                    <v-chip small label color="success" v-else-if="viewdata.stage === 1">Approved</v-chip>
                    <v-chip small label color="error" v-else-if="viewdata.stage === -1">Rejected</v-chip>
                  </div>
                  <div>
                    <v-chip v-if="['bulk_control_amount', 'bulk_control_percent', 'bulk_management_amount', 'bulk_management_percent'].indexOf(viewdata.type) > -1" small label :style="`background-color: ${colors[0] || ''}`">Bulk Threshold</v-chip>
                    <v-chip v-else-if="['threshold'].indexOf(viewdata.type) > -1" small label :style="`background-color: ${colors[1] || ''}`">Single Threshold</v-chip>
                    <v-chip v-else-if="['user_access'].indexOf(viewdata.type) > -1" small label :style="`background-color: ${colors[2] || ''}`">Single User Access</v-chip>
                    <v-chip v-else-if="['bulk_add_submitter','bulk_add_approver','bulk_add_manager'].indexOf(viewdata.type) > -1" small label :style="`background-color: ${colors[3] || ''}`">Bulk Access Addition</v-chip>
                    <v-chip v-else-if="['bulk_remove_submitter','bulk_remove_approver','bulk_remove_manager'].indexOf(viewdata.type) > -1" small label :style="`background-color: ${colors[4] || ''}`">Bulk Access Removal</v-chip>
                  </div>
                </div>
                <v-spacer></v-spacer>
                <div class="mx-2">
                  <div class="grey--text line-1 mb-1">Submitted By</div>
                  <div class="body-1 font-weight-bold">{{(viewdata.submitter_detail || {}).name}}</div>
                  <div class="line-1">{{$nova.formatDate(viewdata.submitdate)}}</div>
                </div>
                <div class="mx-2" v-if="viewdata.stage === 1">
                  <div class="grey--text line-1 mb-1">Approved By</div>
                  <div class="body-1 font-weight-bold">{{(viewdata.approver_detail || {}).name}}</div>
                  <div class="line-1">{{$nova.formatDate(viewdata.approvedate)}}</div>
                </div>
                <div class="mx-2" v-if="viewdata.stage === -1">
                  <div class="grey--text line-1 mb-1">Rejected By</div>
                  <div class="body-1 font-weight-bold">{{(viewdata.approver_detail || {}).name}}</div>
                  <div class="line-1">{{$nova.formatDate(viewdata.approvedate)}}</div>
                </div>
              </div>
              <div class="mt-4">
                <div class="" v-html="viewdata.message"></div>
              </div>
            </v-card-text>
            <v-card-text class="d-flex" v-if="viewdata.stage===0">
              <v-spacer></v-spacer>
              <v-btn small color="error" class="mr-2" @click="performRejection(viewdata._id)">Reject</v-btn>
              <v-btn small color="success" @click="performApproval(viewdata._id)">Approve</v-btn>
            </v-card-text>
          </v-card>
        </v-dialog> -->

    </div>
  </div>
</template>

<script>
import ApprovalsList from "./ApprovalsList.vue";

export default {
  name: 'varienceanalysis_approvals',
  // props: ['props'],
  data: function(){
    return {
      loading: false,
      data: [],
      meta: {},
      viewdialog: false,
      viewloading: false,
      viewdata: {},
      colors: [],
      filterOptionsData:[]
    }
  },
  components: {
    ApprovalsList
  },
  activated() {
    // this.refreshData();
  },
  created(){
    this.getFilterData()
  },
  mounted() {
    this.colors = this.$nova.getColors(5,0.2);
    // this.refreshData();
  },
  methods: {
    refreshData() {
      this.getData()
    },
    setLoading(v) {
      this.loading = !!v;
    },
    getFilterData(){
      this.loading = true;
      this.axios.post("/v2/varianceanalysis/approvals/getAccessApprovals", {"filter":{"page":0, "sync_type":"auto", "type":"approvals"} }).then(dt => {
        if (dt.data.status === "success") {
          this.filterOptionsData = [];
          this.$nextTick(() => {
            this.filterOptionsData = (dt.data || {}).data || [];
          })
          this.getData()
        } else throw new Error("Error reading data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        
      })
    },
    getData() {
      this.loading = true;
      this.axios.post("/v2/varianceanalysis/approvals/getlist", { filter: { page: 0, limit: 50 } }).then(dt => {
        if (dt.data.status === "success") {
          this.data = [];
          this.$nextTick(() => {
            this.data = (dt.data || {}).data || [];
            this.meta = (dt.data || {}).meta || {};
          })
        } else throw new Error("Error reading data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
        
      }).finally(() => {
        this.loading = false;
      })
    },
    performApproval(id){
      this.viewloading = true;
      this.axios.post("/v2/varianceanalysis/approvals/approve/"+id).then(dt => {
        if(dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Change request approved and the changes will be impacted only if there is/are no conflicts");
          this.refreshData();
          this.viewdialog = false;
        }else throw new Error ("Error approving request");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.viewloading = false;
      })
    },
    performRejection(id){
      this.viewloading = true;
      this.axios.post("/v2/varianceanalysis/approvals/reject/"+id).then(dt => {
        if(dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Change request rejected");
          this.refreshData();
          this.viewdialog = false;
        }else throw new Error ("Error rejecting request");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.viewloading = false;
      })
    }
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style scoped>
/* .subs-icon{
  background-color: var(--v-primary-base) !important;
  color: white;
} */
</style>
